import React from 'react'
import { useSEO } from './stateContext'

const DomainInfo = () => {
  const { results } = useSEO();
  return (
    <div style={tss.cardDesign}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Domain Information</h3>
      </div>
      <div style={tss.contentWrapper}>
        {/* Display Age and Creation Date */}
        <p style={tss.resultStyle}>
          {/* <strong>Domain Age:</strong> {results.domainInfo?.age ? `${results.domainInfo.age} ` : "Not available"} */}
        </p>
        {/* {results.domainInfo?.creationDate && (
        <p style={tss.resultStyle}>
          <strong>Creation Date:</strong> {results.domainInfo.creationDate}
        </p>
      )} */}

        {/* Table for additional domain information */}
        <table style={tss.table}>
          <thead>
            <tr>
              <th style={tss.tableHeader}>Field</th>
              <th style={tss.tableHeader}>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tss.tableCell}>Domain Name</td>
              <td style={tss.tableCell}>{results.domainInfo?.domainName || "N/A"}</td>
            </tr>
            <tr>
              <td style={tss.tableCell}>Domain Created On</td>
              <td style={tss.tableCell}>{results.domainInfo?.domainCreatedOn || "N/A"}</td>
            </tr>
            <tr>
              <td style={tss.tableCell}>Age</td>
              <td style={tss.tableCell}>{results.domainInfo?.age ? `${results.domainInfo.age}  ` : "Not available"}</td>
            </tr>
            <tr>
              <td style={tss.tableCell}>Domain Updated On</td>
              <td style={tss.tableCell}>{results.domainInfo?.domainUpdatedOn || "N/A"}</td>
            </tr>
            <tr>
              <td style={tss.tableCell}>Domain Expiration Date</td>
              <td style={tss.tableCell}>{results.domainInfo?.domainExpirationDate || "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DomainInfo

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
 //   borderRadius: "8px 8px 0 0",
    // backgroundImage:"linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(183,52,203,1) 0%, rgba(226,226,226,1) 100%, rgba(45,220,255,0.42638305322128855) 100%)",
  //  backgroundImage: "linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
  backgroundColor:"red",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px", // Added for some top spacing
    paddingBottom: "10px" // Added for some bottom spacing
  },
  titleStyle: {
    fontSize: "1.1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.9rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
    paddingLeft:"15px"

  },

  // Table styles
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px",
    fontSize: "0.9rem",
    color: "black",

  },
  tableHeader: {
    backgroundColor: "#f0f0f0",
    borderBottom: "2px solid #dee2e6",
    padding: "8px",
    textAlign: "left",
    fontWeight: "bold",
  },
  tableCell: {
    padding: "8px",
    borderBottom: "1px solid #dee2e6",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
};