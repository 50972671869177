import React from "react";
import "./about.css";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <div className="about-cover">
        <div className="about-wrapper">
          <div className="about-main-text">
            <h1>About Us</h1>
          </div>
          <div className="about-secondary-text">
            <h2>CrawlMyWeb: Simplifying SEO for Everyone</h2>
            <p>
              {" "}
              At CrawlMyWeb, we’re dedicated to making search engine
              optimization (SEO) straightforward and accessible. Our
              professional-quality SEO analysis tools and essential monitoring
              solutions empower small-business owners, webmasters, and SEO
              experts to elevate their online presence. By providing intuitive,
              easy-to-understand tools, we've helped countless users optimize
              their websites with confidence.
            </p>

            <h2>Who We Are</h2>
            <p>
              CrawlMyWeb is powered by a passionate team of SEO experts and
              webmasters with over 15 years of experience in the ever-evolving
              SEO landscape. From changing algorithms to penalties and ranking
              factors, we've witnessed the evolution of SEO since its earliest
              days. This in-depth understanding allows us to guide webmasters on
              where to focus their time and efforts to achieve meaningful
              results.
            </p>

            <h2>Our Approach</h2>
            <p>
              We believe SEO doesn’t have to be overwhelming. While Google’s
              ranking system considers over 200 factors, the core goals of SEO
              remain clear:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Helping search engines understand your site’s focus or niche.
              </li>
              <li>
                Improving user experience and building credibility through
                relevant backlinks to showcase your site’s value.
              </li>
            </ul>
            <p>
              With CrawlMyWeb, you gain access to a comprehensive set of SEO
              tools that demystify how search engines perceive your site. In
              just seconds, our analysis reveals technical issues or SEO gaps
              that could be affecting your rankings.
            </p>
            <h2>Why Choose Us?</h2>
            <p>
              We pride ourselves on delivering actionable insights based on the
              latest SEO trends. For instance, factors like website loading
              speed, which are increasingly vital for search rankings, are an
              integral part of our analysis. Whether it's evaluating technical
              performance or identifying backlink opportunities, CrawlMyWeb
              equips you to stay competitive in today’s fast-paced digital
              world.
            </p>
            <h2>Keeping Up with SEO’s Evolution</h2>
            <p>
              SEO is one of the fastest-changing fields in digital marketing,
              with frequent updates like Google Panda, Penguin, and Hummingbird
              reshaping the landscape. CrawlMyWeb continually updates its tools
              and recommendations to reflect these changes, ensuring your site
              aligns with the latest search engine algorithms.
            </p>
            <p>
              Explore CrawlMyWeb today and take the guesswork out of SEO. Let’s
              build a stronger, more visible online presence—together.
            </p>
            <p className="bottom-text">Please feel free to <Link className="link" to={"/contact"}>contact us</Link> if you have any needs, suggestions or questions.

</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
