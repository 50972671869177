import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/NavBar/Navbar.jsx";
import Result from "./components/Result";
import { SEOProvider } from "./components/stateContext";
import Navbar1 from "./components/NavBar/NavBar1.jsx";
import LandingPage from "./components/LandingPage";
import Dashboard from "./components/Dashboard.jsx";
import Pricing from "./components/Pricing.jsx";
import Footer from "./components/Footer/Footer.jsx";
const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <p>Loading...</p>;
  if (!isAuthenticated) return <p>Please log in to access this page.</p>;

  return element;
};
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Navbar />
        <LandingPage />
        <Footer />

      </>
    ),
  },

  {
    path: "/aboutus",
    element: (
      <>
        <Navbar />
        <About />
        <Footer />

      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <Navbar />
        <Contact />
        <Footer />

      </>
    ),
  },
  {
    path: "/result",
    element: (
      <>
        <Navbar1 />
        <Result />
        <Footer />

      </>
    ),
  },
  {
    path: "/dashboard/:loginId",
    element: (
      <ProtectedRoute
        element={
          <>
            <Navbar />
            <Dashboard />
            <Footer />

          </>
        }
      />
    ),
  },
  
  {
    path: "/contact",
    element: (
      <>
        <Navbar />
        <Contact />
        <Footer />

      </>
    ),
  },
  // {
  //   path: "/pricing",
  //   element: (
  //     <>
  //       <Navbar />
  //       <Pricing />
  //       <Footer />

  //     </>
  //   ),
  // },

]);
const App = () => {
  return (
    <>
      <SEOProvider>
        <RouterProvider router={router} />
      </SEOProvider>
    </>
  );
};

export default App;
