// // import React from 'react';
// // import ReactDOM from 'react-dom/client';
// // import Provider from './components/ui/provider'
// // import App from './App';

// // const root = ReactDOM.createRoot(document.getElementById('root'));
// // root.render(
// //     <React.StrictMode>
// //         <Provider>

// //       <App />
// //       </Provider>
// //    </React.StrictMode>
// // );
// import React from "react";
// import ReactDOM from "react-dom/client";
// import Provider from "./components/ui/provider";
// import App from "./App";
// import { Auth0Provider } from "@auth0/auth0-react";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <>
//     <Auth0Provider
//       domain="dev-a310ckx6cm5e4mx1.us.auth0.com"
//       clientId="wEliU4IWuT6Q1X7tQ0lfTctaDnZWYVqJ"
//       authorizationParams={{
//         audience: "https://dev-a310ckx6cm5e4mx1.us.auth0.com/api/v2/",
//         redirect_uri: window.location.origin,
//       }}
//     >
//       <Provider>
//         <App />
//       </Provider>
//     </Auth0Provider>
//   </>
// );


import React from "react";
import ReactDOM from "react-dom/client";
import Provider from "./components/ui/provider";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

// Access environment variables from .env file
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin,
      }}
    >
      <Provider>
        <App />
      </Provider>
    </Auth0Provider>
  </>
);