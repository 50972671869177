
import React, { useState, useEffect } from "react";
import './dashboard.css';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const Dashboard = () => {
  // const { user, isAuthenticated } = useAuth0();
  const { user } = useAuth0();

  const loginId = user?.sub;

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!loginId) return;

    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`http://localhost:8000/seo/dashboard/${loginId}`);
        setUserData(response.data);
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to load user data.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [loginId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="dashboard">
      {/* Profile Section */}
      <div className="profile">
        <img src={userData?.picture} referrerPolicy="no-referrer" alt={`${userData?.name}'s profile`} />
        <h2>{userData?.name}</h2>
      </div>

      {/* Stats Section as Table */}
      <div className="stats">
        <h2>Stats</h2>
        <table>
          <tbody>
            <tr>
              <td><strong>Websites Searched:</strong></td>
              <td>{userData?.searchCount}</td>
            </tr>
            <tr>
              <td><strong>Account Created :</strong></td>
              <td>{new Date(userData?.lastLogin).toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Search History Section as Table */}
      {userData?.searchHistory && userData.searchHistory.length > 0 && (
        <div className="search-history">
          <h2>Search History</h2>
          <table>
            <thead>
              <tr>
                <th>Website</th>
                <th>Searched At</th>
              </tr>
            </thead>
            <tbody>
              {userData.searchHistory.map((search, index) => (
                <tr key={index}>
                  <td>
                    <a href={search.website} target="_blank" rel="noopener noreferrer">
                      {search.website}
                    </a>
                  </td>
                  <td>{new Date(search.searchedAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
