import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Import the CSS file
import logo from "../../assets/Logo2.png";

const Navbar = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const loginId = user?.sub; 

  return (
    <header className="navbar">
      <div className="navbar-content">
        <div className="logo">
          <Link to="/" className="logo-link">
            <img src={logo} alt="crawlmyweb.com" className="logo-img" />
          </Link>
        </div>
        
        <nav className="nav-links">
          <Link to="/aboutus" className="nav-link">
            About Us
          </Link>
       
          <Link to="/contact" className="nav-link">
            Contact Us
          </Link>
          {isAuthenticated ? (
            <>
              <Link to={`/dashboard/${loginId}`} className="nav-link">
                Dashboard
              </Link>
              <button
                className="auth-btn"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                Logout
              </button>
            </>
          ) : (
            <button className="auth-btn" onClick={() => loginWithRedirect()}>
              Login
            </button>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
