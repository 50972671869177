import React from 'react';
import { useSEO } from './stateContext';

const RobotsTxt = () => {
    const { results } = useSEO();
    let formattedUrl;

    formattedUrl = results.url && (results.url.startsWith('http://') || results.url.startsWith('https://'))
        ? results.url.replace(/^http:\/\//, 'https://')
        : `https://${results.url || 'default-url.com'}`;

  //  console.log("robots.txt", formattedUrl);

    // Determine star rating based on robots.txt presence
    const stars = results.robotsTxtPresent ? 5 : 1;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>
                    robots.txt
                </h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <span style={{ fontWeight: "bold" }}>robots.txt: </span>
                    {results.robotsTxtPresent ? (
                        <div>
                            <a
                                style={{
                                    color: "#007bff",
                                    textDecoration: "none",
                                }}
                                href={`${formattedUrl}/robots.txt`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`${formattedUrl}/robots.txt`}
                            </a>
                            <p style={tss.benefitText}>
                            ✅ Great! A robots.txt file allows you to control how search engines crawl and index your site. 
                                It improves your website's SEO by preventing unnecessary or duplicate pages from being indexed, 
                                ensuring efficient crawling.
                            </p>
                        </div>
                    ) : (
                        <div style={tss.warningContainer}>
                            <span style={tss.warningIcon}>⚠️</span>
                            <span>
                                <span style={{ color: '#dc3545', fontWeight: 'bold' }}>Not Available</span>
                                <p style={tss.warningText}>
                                    Without a robots.txt file, search engines may crawl unnecessary or restricted pages, 
                                    leading to inefficient indexing and a potential negative impact on SEO.
                                    Consider adding one to improve search engine performance and control.
                                </p>
                            </span>
                        </div>
                    )}
                </p>
            </div>
        </div>
    );
};

export default RobotsTxt;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        // borderRadius: "8px 8px 0 0",
        // backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        backgroundColor:"red",
        display: "flex",
        alignItems: "center",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px", // Added for some top spacing
        paddingBottom: "10px", // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: "1.1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.9rem",
        color: "#4b5563",
        
        paddingLeft:"15px"
    },
    contentWrapper: {
        padding: "10px 0",
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
    },
    warningIcon: {
        fontSize: '1.5rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    benefitText: {
        fontSize: '0.9rem',
        color: '#28a745',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};
