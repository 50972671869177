  import React, { useContext, useState, useEffect } from "react";
  import { SEOContext } from "./stateContext";
  import axios from "axios";
  import { useNavigate } from "react-router-dom";
  import { useAuth0 } from "@auth0/auth0-react";
  import "./ahref.css";
  import SEOToolbox from "./seoToolBox";

  const LandingPage = () => {
    const [url, setUrl] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);


    const { setResults } = useContext(SEOContext);
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading } = useAuth0();
    let ullu = { ...user };
    let { name, email, picture } = ullu;
    useEffect(() => {
      if (isAuthenticated && user) {
        localStorage.setItem("userId", user.sub);
      } else if (!isLoading) {
        localStorage.removeItem("userId");
     
      }
    }, [isAuthenticated, user, isLoading]);

    const clearsessionStorage = () => {
      sessionStorage.removeItem("seoResultsData"); // Clear the specific key
    };
    const isValidUrl = (url) => {
      const regex =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:?#&=]*)?$/;
      return regex.test(url);
    };

    const getDeviceDetails = () => {
      const userAgent = navigator.userAgent;
      const platform = navigator.platform || "Unknown OS";
      const resolution = `${window.screen.width}x${window.screen.height}`;
      const deviceType = /Mobi|Android/i.test(userAgent) ? "Mobile" : "Desktop";

      let os = "Unknown OS";
      let osVersion = "Unknown Version";
      let browser = "Unknown";
      let browserVersion = "Unknown";

      // Detect OS and OS version
      if (/Windows NT 10/.test(userAgent)) {
        os = "Windows";
        osVersion = "10";
      } else if (/Windows NT 6.3/.test(userAgent)) {
        os = "Windows";
        osVersion = "8.1";
      } else if (/Windows NT 6.1/.test(userAgent)) {
        os = "Windows";
        osVersion = "7";
      } else if (/Mac OS X/.test(userAgent)) {
        os = "MacOS";
        osVersion =
          userAgent.match(/Mac OS X (10[._\d]+)/)?.[1].replace(/_/g, ".") ||
          "Unknown";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
        osVersion = userAgent.match(/Android ([\d.]+)/)?.[1] || "Unknown";
      } else if (/Linux/.test(userAgent)) {
        os = "Linux";
      } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        os = "iOS";
        osVersion =
          userAgent.match(/OS (\d+_\d+)/)?.[1].replace(/_/g, ".") || "Unknown";
      }

      // Detect browser and version
      if (/Chrome/.test(userAgent)) {
        browser = "Chrome";
        browserVersion = userAgent.match(/Chrome\/([\d.]+)/)?.[1] || "Unknown";
      } else if (/Firefox/.test(userAgent)) {
        browser = "Firefox";
        browserVersion = userAgent.match(/Firefox\/([\d.]+)/)?.[1] || "Unknown";
      } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
        browser = "Safari";
        browserVersion = userAgent.match(/Version\/([\d.]+)/)?.[1] || "Unknown";
      } else if (/Edge/.test(userAgent)) {
        browser = "Edge";
        browserVersion = userAgent.match(/Edg\/([\d.]+)/)?.[1] || "Unknown";
      }

      return {
        os,
        osVersion,
        browser,
        browserVersion,
        resolution,
        deviceType,
      };
    };
    const performChecks = async () => {
      setLoading(true);
      setError("");
      clearsessionStorage();

      const loginId = localStorage.getItem("userId") || null;

      const deviceDetails = getDeviceDetails();
    
      let normalizedUrl = url.trim();
      if (
        !normalizedUrl.startsWith("http://") &&
        !normalizedUrl.startsWith("https://")
      ) {
        normalizedUrl = `http://${normalizedUrl}`;
      }
      if (!isValidUrl(normalizedUrl)) {
        setError("Please enter a valid URL.");
        setLoading(false);

        return;
      }
      try {
        setResults(null); 
        const response = await axios.post(
          process.env.REACT_APP_URL,

          { url: normalizedUrl, loginId, name, email, picture, deviceDetails },
          { withCredentials: true } 
        );
      
        setResults(response.data);

        navigate("/result");
        const fetchPageSpeed = async () => {
          try {
            const pageSpeedResponse = await axios.post(
              process.env.REACT_APP_SPEED,
              { url: normalizedUrl },
              { withCredentials: true }
            );
            const { pageSpeedResults } = pageSpeedResponse.data;

            setResults((prevResults) => {
              const updatedResults = {
                ...prevResults,
                pageSpeedResults,
              };

              return updatedResults;
            });
          } catch (pageSpeedError) {
            console.error("Error fetching PageSpeed results:", pageSpeedError);
          }
        };
        await fetchPageSpeed();
      } catch (err) {
        setError(err.response?.data?.error || "Error performing check");
      } finally {
        setLoading(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        performChecks();
      }
    };
    return (
      <>
      <div className="cover" id='search-input'>
        <div className="seo-audit-container">
          <header className="header">
            <h1 className="main-text">
              {/* Perform a thorough SEO analysis of your website. */}
              Website SEO, Monitoring & Automation Made Easy.


            </h1>
            <p className="secondary-text">
              Identify, prioritize, and fix technical and on-page SEO issues. Keep
              your website in top shape for users and search engines.
            </p>

            {/* {loading && (
              // <div className="loader">
              //   <div></div>
              //   <div></div>
              //   <div></div>
              //   <div></div>
              //   <div></div>
              // </div>
              <div className="progress-bar">
              <div className="progress-bar-inner"></div>
            </div>
            )} */}
                   {loading && (
              <div className="loading-containerr">
                <div className="progress-barr">
                  <div className="progress-bar-innerr"></div>
                </div>
                <p className="loading-text">Loading... Please wait...</p>
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
            <div className="input-container">
              <input
              
                type="text"
                className="search-input"
                placeholder="Enter your website like crawlmyweb.com "
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button className="start-button" onClick={performChecks}>
                Start for Free
              </button>
            </div>

            <p className="subtext">Free for websites with verified ownership</p>
          </header>
        </div>
      </div>
      <SEOToolbox/>
      </>
    );
  };

  export default LandingPage;
