import React from 'react';
import { useSEO } from './stateContext';

const XmlSitemap = () => {
  const { results } = useSEO();
  const stars = results.sitemapUrls && results.sitemapUrls.length > 0 ? 5 : 0; // 5 stars if sitemap is present, otherwise 0 stars

  const renderStars = () => (
    <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
      {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
    </span>
  );

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>XML Sitemap</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars()}
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <strong style={{ fontWeight: 'bold' }}>XML Sitemap: </strong>
          {results.sitemapUrls && results.sitemapUrls.length > 0 ? (
            <div>
              <a
                href={results.sitemapUrls} // Display only the first sitemap URL
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#007bff',
                  textDecoration: 'none',
                }}
              >
                {results.sitemapUrls}
              </a>
              <p style={tss.benefitText}>
                ✅ Great! Having a sitemap ensures search engines can efficiently crawl 
                and index all your website pages. This improves your site's visibility 
                and SEO rankings.
              </p>
            </div>
          ) : (
            <div style={tss.warningContainer}>
              <span style={tss.warningIcon}>⚠️</span>
              <span>
                <span style={{ color: '#dc3545', fontWeight: 'bold' }}>
                  No XML sitemap found.
                </span>
                <p style={tss.warningText}>
                  A sitemap helps search engines index your website efficiently. 
                  Without it, your SEO performance may suffer, as crawlers might miss 
                  some pages on your site. Consider adding a sitemap to improve 
                  discoverability.
                </p>
              </span>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default XmlSitemap;

const tss = {
  cardDesign: {
    position: 'relative',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    marginBottom: '16px',
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    // borderRadius: '8px 8px 0 0',
    // backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    backgroundColor:"red",
    display: 'flex',
    color: 'white',
    paddingLeft: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  titleStyle: {
    fontSize: '1.1rem',
    fontWeight: '600',
    margin: '0',
  },
  resultStyle: {
    fontSize: '0.9rem',
    color: '#4b5563',
    paddingLeft:"15px"
  },
  contentWrapper: {
    padding: '10px 0',
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#dc3545',
  },
  warningIcon: {
    fontSize: '1.5rem',
    marginRight: '10px',
  },
  warningText: {
    fontSize: '0.9rem',
    color: '#4b5563',
    marginTop: '5px',
  },
  benefitText: {
    fontSize: '0.9rem',
    color: '#28a745',
    marginTop: '5px',
  },
  avoidBreak: {
    pageBreakInside: 'avoid',
  },
};
