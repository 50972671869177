import React from 'react';
import { useSEO } from './stateContext';

const HtmlSitemapCheck = () => {
    const { results } = useSEO();

    // Determine star rating based on the presence of HTML Sitemap
    const stars = results.htmlSitemapPresent ? 5 : 0;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>HTML Sitemap</h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <strong style={{ fontWeight: 'bold' }}>HTML Sitemap:</strong>{' '}
                    {results.htmlSitemapPresent ? (
                        <>
                            Available. <p style={{ color: '#28a745' }}>✅ Great! Your HTML Sitemap is available, helping search engines crawl your site efficiently.</p>
                        </>
                    ) : (
                        <>
                            Not Available. <p style={{ color: '#dc3545' }}>⚠️ Missing HTML Sitemap. Adding one can help improve search engine indexing and visibility.</p>
                        </>
                    )}
                </p>
            </div>
        </div>
    );
};

export default HtmlSitemapCheck;

const tss = {
    cardDesign: {
        position: 'relative',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        marginBottom: '16px',
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        // borderRadius: '8px 8px 0 0',
        // backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        backgroundColor:"red",
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        paddingLeft: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    titleStyle: {
        fontSize: '1.1rem',
        fontWeight: '600',
        margin: '0',
    },
    resultStyle: {
        fontSize: '0.9rem',
        color: '#4b5563',
        
        paddingLeft:"15px"
    },
    contentWrapper: {
        padding: '10px 0',
    },
    avoidBreak: {
        pageBreakInside: 'avoid',
    },
};
