import React from 'react'
import "./footer.css"
import logo from "../../assets/Logo3.png"
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-left">
            {/* <img src={logo} alt="crawlmyweb.com" /> */}
            <Link to="/">
            <img src={logo} alt="crawlmyweb.com" className="logo-img" />
          </Link>
          {/* <a href='/'>             <img src={logo} alt="crawlmyweb.com" className="logo-img" />
          </a> */}
            <p>Website SEO, Monitoring & Automation Made Easy.</p>
            <div className="social-icons">
              {/* <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a> */}
            </div>
          </div>
          <div className="footer-right">
              <h4>Company</h4>
              <ul>
                {/* <li><a href="#">About us</a></li> */}
                <li><Link to="/aboutus">About Us</Link> </li>
                {/* <li><a href="#">SEO Checkups</a></li> */}
                <li><Link to="/">SEO Checkups</Link></li>
                <li> <Link to="/contact">Contact</Link></li>
              </ul>
            </div>
        </div>
        <div className="footer-bottom">
          <p>© Crawl My Web • All rights reserved</p>
        </div>
      </footer>
    );
  };

export default Footer