import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./Navbar.css";
import html2pdf from "html2pdf.js";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/Logo2.png";
import { useSEO } from "../stateContext";

const Navbar1 = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  const loginId = user?.sub;
  const navigate = useNavigate();
  const { results } = useSEO();

  const downloadPDF = (result) => {
    const element = document.getElementById("result-content"); 
    if (!element) {
      console.error("Element not found");
      return;
    }
    const url = new URL(results.url);
    const filenamePrefix = url.hostname;


    const dynamicFilename = `${filenamePrefix}_seo_results_crawlmyweb.com.pdf`;

    const opt = {
      margin: 1,
      filename: dynamicFilename,
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(opt).save();
  };


  const handleSearchClick = () => {
    navigate("/"); 
  };

  return (
    <header className="navbar">
      <div className="navbar-content">
        <div className="logo">
          <Link to="/" className="logo-link">
            <img src={logo} alt="crawlmyweb.com" className="logo-img" />
            {/* <span className="logo-text">Crawl My Web</span> */}
          </Link>
        </div>
        <nav className="nav-links">
          <div style={style.buttonContainer}>
            <button style={style.searchBtn} onClick={handleSearchClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M10 2a8 8 0 1 0 5.657 13.657l4.95 4.95a1 1 0 0 0 1.414-1.414l-4.95-4.95A8 8 0 0 0 10 2zm0 2a6 6 0 1 1 0 12 6 6 0 0 1 0-12z"
                />
              </svg>
            </button>
            <Link to="/aboutus" style={style.navLink}>
              About Us
            </Link>
            {/* <Link to="/pricing" style={style.navLink}>
              Pricing
            </Link> */}
            <Link to="/contact" style={style.navLink}>
              Contact Us
            </Link>
            {/* <button style={style.downloadBtn} onClick={downloadPDF}>
                            Download in PDF format
                        </button> */}


{isAuthenticated ? (
  <button style={style.downloadBtn} onClick={downloadPDF}>
    Export as PDF
  </button>
) : (
  <button
    style={style.downloadBtn}
    onClick={() => {
      loginWithRedirect();
    }}
  >
    Export as PDF
  </button>
)}
{/* <button
  style={style.downloadBtn}
  onClick={() => {
    if (isAuthenticated) {
      downloadPDF();
    } else {
      // loginWithRedirect({
      //   appState: { returnTo: window.location.pathname }, // Save current path
      // });
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
      console.log("Login triggered with appState:", { returnTo: window.location.pathname });
      
    }
  }}
>
  Export as PDF
</button> */}


            {isAuthenticated ? (
              <>
                <Link to={`/dashboard/${loginId}`} style={style.navLink}>
                  Dashboard
                </Link>
                {/* <Link to="/dashboard" style={style.navLink}>
                                            Dashboard
                                          </Link> */}
                {/* {isAuthenticated && (
                  <button style={style.downloadBtn} onClick={downloadPDF}>
                    Export as PDF
                  </button>
                )} */}
                <button
                  style={style.authBtn}
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Logout
                </button>
              </>
            ) : (
              <button className="auth-btn" onClick={() => loginWithRedirect({
                appState: { returnTo: window.location.pathname }, // Save current path
              })}>
                Login
              </button>
            )}

          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar1;

const style = {
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  searchBtn: {
    border: "none",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "1rem",
    backgroundColor: "#4285f4",
    color: "#fff",
    // backgroundColor:"#fff",
    // color:"#4285f4",
    padding: "6px",
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  downloadBtn: {
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    backgroundColor: "#34a853",
    color: "#fff",
    // padding: "4px 5px",
    padding: "3px 5px 2px 5px",

  },
  authBtn: {
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "1rem",
    // backgroundColor: '#fbbc05',
    backgroundColor: "#2110ff",
    color: "#fff",
    padding: "7px 7px",
    marginLeft: "10px",
  },
  navLink: {
    marginRight: "10px",
    // color: "#4285f4",
    color:"blue",
    textDecoration: "none",
    fontSize:"12px",
    // fontWeight: "bold",
  },
};
