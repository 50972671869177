import React from 'react';
import { useSEO } from './stateContext';

const FlashContent = () => {
    const { results } = useSEO();
    const stars = results.flashContentPresent ? 0 : 5;
    
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    // Function to show SEO message based on flash content presence
    const seoMessage = () => {
        if (results.flashContentPresent) {
            return (
                <p style={{ color: 'red' }}>
                   ⚠️ Flash content found! It can negatively affect SEO as search engines cannot crawl or index Flash content. It may also cause compatibility issues on mobile devices, potentially leading to a poor user experience.
                </p>
            );
        } else {
            return (
                <p style={{ color: 'green' }}>
                     ✅ No Flash content found. This is great for SEO as search engines can easily crawl and index your content, and your site will be mobile-friendly, ensuring a better user experience.
                </p>
            );
        }
    };

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Flash Content</h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()} 
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <strong style={{ fontWeight: 'bold' }}>Flash Content:</strong>
                    {results.flashContentPresent ? 'Yes' : 'No'}
                </p>
                {/* Display SEO message based on flash content presence */}
                {seoMessage()}
            </div>
        </div>
    );
};

export default FlashContent;

const tss = {
    cardDesign: {
        position: 'relative',
        backgroundColor: '#f8f9fa',
        border: '0px solid #dee2e6',
        borderRadius: '8px',
        marginBottom: '16px',
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        // borderRadius: '8px 8px 0 0',
        // backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        backgroundColor:"red",
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        paddingLeft: '15px',
        paddingTop: '10px', // Added for some top spacing
        paddingBottom: '10px', // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: '1.1rem',
        fontWeight: '600',
        margin: '0',
    },
    resultStyle: {
        fontSize: '0.9rem',
        color: '#4b5563',
    },
    contentWrapper: {
        padding: '10px 0',
        paddingLeft:"15px"

    },
    avoidBreak: {
        pageBreakInside: 'avoid',
    },
};
