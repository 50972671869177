import React from 'react';
import { useSEO } from './stateContext';

const LanguageCheck = () => {
    const { results } = useSEO();

    // Determine star rating based on the presence of lang attribute
    const stars = results.langAttribute ? 5 : 1;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>
                    Language
                </h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    {results.langAttribute ? (
                        <div>
                            <p>
                                 Your page is using the <strong>lang</strong> attribute: <strong>{results.langAttribute}</strong>.
                            </p>
                            <p style={tss.benefitText}>
                            ✅ This helps search engines understand the language of your content, improving SEO and enhancing the user experience for multilingual audiences.
                            </p>
                        </div>
                    ) : (
                        <div style={tss.warningContainer}>
                            <span style={tss.warningIcon}>⚠️</span>
                            <span>
                                <p>
                                    ❌ Your page is not using the <strong>lang</strong> attribute.
                                </p>
                                <p style={tss.warningText}>
                                    This can lead to confusion for search engines and assistive technologies, potentially reducing accessibility and SEO performance for international users.
                                </p>
                            </span>
                        </div>
                    )}
                </p>
            </div>
        </div>
    );
};

export default LanguageCheck;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        // borderRadius: "8px 8px 0 0",
        // backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        backgroundColor:"red",
        display: "flex",
        alignItems: "center",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px", // Added for some top spacing
        paddingBottom: "10px" // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: "1.1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.9rem",
        color: "#4b5563",
        
        paddingLeft:"15px"
    },
    contentWrapper: {
        padding: "10px 0",
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
    },
    warningIcon: {
        fontSize: '1.5rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    benefitText: {
        fontSize: '0.9rem',
        color: '#28a745',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};
