import React, { useEffect, useState } from 'react';
import { useSEO } from './stateContext';

const GooglePageSpeed = () => {
  const { results } = useSEO();  // Fetch results from the context
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (results.pageSpeedResults.speed  && results.pageSpeedResults.speed !== undefined) {
      setLoading(false);  // Stop loading when speed data is available
    }
  }, [results.pageSpeedResults]);  

  useEffect(() => {
  //  console .log('Updated results:', results);
  //   console.log("page speed results are",results.pageSpeedResults ? results.pageSpeedResults.speed : "N/A")
  }, [results]);  // This will log the updated results each time the component re-renders
  
  if (loading) {
    return (
      <div style={tss.loadingContainer}>
        <p>⏳ Loading Google PageSpeed data...</p>
      </div>
    );
  }
  
  const renderStars = (score) => {
    const rating = Math.round(score / 20);  // Convert score to star rating
    return (
      <span style={{ fontSize: '1.5rem', color: '#f39c12' }}>
        {'★'.repeat(rating)} {/* Filled stars */}
        {'☆'.repeat(5 - rating)} {/* Empty stars */}
      </span>
    );
  };

  const renderImpact = (score) => {
    if (score > 80) {
      return (
        <p style={tss.benefitText}>
          🎉 Your website has excellent speed!
        </p>
      );
    } else if (score > 50) {
      return (
        <p style={tss.warningText}>
          ⚠️ Your website's speed is average.
        </p>
      );
    } else {
      return (
        <p style={tss.errorText}>
          ❌ Your website's speed is below average.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Google PageSpeed</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars(results.pageSpeedResults.speed)} {/* Display star rating */}
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          {results.pageSpeedResults.speed && (
            <div>
              <p style={{color: "#333" }}>
                <strong style={{ fontWeight: "bold" }}>PageSpeed Score:</strong>{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color:
                      results.pageSpeedResults.speed > 80
                        ? "#28a745"
                        : results.pageSpeedResults.speed > 50
                        ? "#ffc107"
                        : "red",
                  }}
                >
                  {Math.round(results.pageSpeedResults.speed)}%
                </span>
              </p>
              {renderImpact(results.pageSpeedResults.speed)} {/* Render impact */}
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default GooglePageSpeed;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    backgroundColor:"red",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1.1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.9rem",
    color: "#4b5563",
    paddingLeft:"15px"

  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  warningText: {
    fontSize: "0.9rem",
    color: "#ffc107",
    marginTop: "10px",
  },
  errorText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
};
