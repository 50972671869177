import React from 'react';
import { useSEO } from './stateContext';

const HttpsRedirectCheck = () => {
  const { results } = useSEO();

  const calculateHttpsRating = (status) => {
    return status === "Your page successfully redirects to a HTTPS (SSL) secure version." ? 5 : 1;
  };

  const httpsRating = calculateHttpsRating(results.checkHttpsRedirect.message);

  const renderStars = (rating) => (
    <span style={{ fontSize: '1.5rem', color: '#f39c12' }}>
      {'★'.repeat(rating)}
      {'☆'.repeat(5 - rating)}
    </span>
  );

  // Function to render impact messages
  const renderImpactMessage = (status) => {
    if (status === "Your page successfully redirects to a HTTPS (SSL) secure version.") {
      return (
        <p style={tss.benefitText}>
          ✅ **Your site is secure!** Using HTTPS improves trust, ensures data encryption, and boosts SEO rankings. Modern browsers also flag HTTP sites as "Not Secure," so your HTTPS setup prevents this warning.
        </p>
      );
    } else {
      return (
        <p style={tss.errorText}>
          ❌ **Your site is not redirecting to HTTPS.** This can lead to security vulnerabilities, reduced trust from users, and lower SEO rankings. Enable HTTPS to secure your site and improve its performance.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>HTTPS Redirect Check</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars(httpsRating)}
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <strong style={{ fontWeight: "bold" }}>HTTPS Redirect Check:</strong> {results.checkHttpsRedirect.message}
        </p>
        {/* Render the impact message */}
        {renderImpactMessage(results.checkHttpsRedirect.message)}
      </div>
    </div>
  );
};

export default HttpsRedirectCheck;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    backgroundColor:"red",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1.1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.9rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
    paddingLeft:"15px"

  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  errorText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
};
