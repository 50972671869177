import React from 'react';
import { useSEO } from './stateContext';

const Iframes = () => {
  const { results } = useSEO();

  // Function to calculate star rating based on iframe count
  const calculateIframeRating = (iframeCount) => {
    if (iframeCount === 0) return 5;
    if (iframeCount <= 2) return 4;
    if (iframeCount <= 5) return 3;
    if (iframeCount <= 10) return 2;
    return 1;
  };

  // Calculate rating based on the iframe count
  const iframeRating = calculateIframeRating(results.iframeCount);

  // Function to render stars based on rating
  const renderStars = (rating) => (
    <span style={{ fontSize: '1.5rem', color: '#f39c12' }}>
      {'★'.repeat(rating)}
      {'☆'.repeat(5 - rating)}
    </span>
  );

  // Function to show SEO message based on iframe count
  const seoMessage = () => {
    if (results.iframeCount === 0) {
      return (
        <p style={{ color: 'green' }}>
         ✅ No iframes found. This can enhance your SEO by improving page load speed, better mobile-friendliness, and making it easier for search engines to crawl and index your content.
</p>
      );
    } else {
      return (
        <p style={{ color: 'red' }}>
         ⚠️ Presence of iframes can slow down page load times, affect mobile usability, and impact SEO rankings if not handled properly.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>iFrames</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars(iframeRating)} 
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <strong style={{ fontWeight: 'bold' }}>iframe found:</strong> {results.iframeCount ? 'Yes' : 'No'}
        </p>
        {/* Display SEO message based on iframe count */}
        {seoMessage()}
        {/* Display iframe details */}
        {results.iframeCount > 0 && (
          <div>
            <ul style={{ listStyleType: 'none', padding: '0' }}>
              {results.iframeDetails.map((src, index) => (
                <li key={index} style={{ marginBottom: '8px', fontSize: '0.7rem', color: '#007bff' }}>
                  <a href={src} target="_blank" rel="noopener noreferrer">{src}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Iframes;

const tss = {
  cardDesign: {
    position: 'relative',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    marginBottom: '16px',
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    // borderRadius: '8px 8px 0 0',
    // backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    backgroundColor:"red",
    display: 'flex',
    color: 'white',
    paddingLeft: '15px',
    paddingTop: '10px', // Added for some top spacing
    paddingBottom: '10px', // Added for some bottom spacing
  },
  titleStyle: {
    fontSize: '1.1rem',
    fontWeight: '600',
    margin: '0',
  },
  resultStyle: {
    fontSize: '0.9rem',
    color: '#4b5563',
  },
  contentWrapper: {
    padding: '10px 0',
    paddingLeft:"15px"

  },
  avoidBreak: {
    pageBreakInside: 'avoid',
  },
};
