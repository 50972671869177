import React, { useEffect, useState } from 'react';
import { useSEO } from './stateContext';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import MissingAltTags from './MissingAltTags';
import XmlSitemap from './XmlSitemap';
import DomainInfo from './DomainInfo';
import SslCertificateDetails from './SslCertificateDetails';
import EmailPrivacy from './EmailPrivacy';
import SocialMediaProfiles from './SocialMediaProfiles';
import Iframes from './Iframes';
import BusinessProfile1 from './BusinessProfile1';
import HeadingTags from './HeadingsTags';
import MetaTagsTnD from './MetaTagsTnD';
import GooglePageSpeed from './GooglePageSpeed';
import SeoScorePercent from './SeoScorePercent';
import BrokenLinks from './BrokenLinks';
import HttpsRedirectCheck from './HttpsRedirectCheck';
import FaviconCheck from './FaviconCheck';
import CanonicalTagCheck from './CanonicalTagCheck';
import HreflangUsage from './HreflangUsage';
import DuplicateContent from './DuplicateContent';
// import SuggestedKeywords from './SuggestedKeywords';
import AnalyticsPresence from './AnalyticsPresence';
import RobotsTxt from './RobotsTxt';
import HtmlSitemapCheck from './HtmlSitemapCheck';
import LanguageCheck from './LanguageCheck';
import FlashContent from './FlashContent';
import "./Results.css";
import './Resultserp.css';
import CodeToTextRatio from './CodeToTextRatio';
import { LinearProgress } from '@mui/material';

const Result = () => {
  const { results, setResults } = useSEO();

  const [storedResults, setStoredResults] = useState(null);
  const storageKey = "seoResultsData"; 
  // Fetch stored  from localStorage
  useEffect(() => {
    const storedData = sessionStorage.getItem(storageKey);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setStoredResults(parsedData);
      setResults(parsedData); 
    }
  }, [setResults]);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if ((event.ctrlKey && event.key === 'c') || (event.ctrlKey && event.key === 'p')) {
  //       event.preventDefault(); // Disable the default action
  //     }
  //   };

  //   // Add the event listener
  //   window.addEventListener('keydown', handleKeyDown);

  //   // Cleanup on unmount
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey && event.key === 'c') || (event.ctrlKey && event.key === 'p')) {
        event.preventDefault(); // Disable the default action
      }
    };

    const handleRightClick = (event) => {
      event.preventDefault(); // Disable right-click context menu
    };

    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('contextmenu', handleRightClick);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);



  useEffect(() => {
    if (results) {
      sessionStorage.removeItem(storageKey);
      sessionStorage.setItem(storageKey, JSON.stringify(results)); // Convert results to a string
    //   console.log("Saved results to sessionStorage:", results);
    }
  }, [results]);

  // Fallback to stored results or context results
  const currentResults = results || storedResults;

  if (!currentResults) {
    // return <div>Loading...</div>; // or some placeholder until results are ready
    return <LinearProgress />

  }


  const getColorByPercentage = (value) => {
    if (value >= 90) return '#28a745'; // Green for 90-100
    else if (value >= 75) return '#ffc107'; // Yellow for 75-89
    else if (value >= 50) return '#dc3545'; // Red for 50-74
    return '#dc3545'; // Red for below 50
  };
  const seoScore = Math.round(results.seoScore) || 0;
  const usabilityScore = Math.min(Math.max(results.usabilityScore || 0, 0), 100) -5 ;
  const performanceScore = results?.pageSpeedResults?.speed ? Math.min(Math.max(Math.round(results.pageSpeedResults.speed) || 0, 0), 100) : 0;

  // const totalScores = [seoScore, usabilityScore, performanceScore];
  const totalScores = [seoScore, usabilityScore, performanceScore];

  const validScores = totalScores.filter(score => !isNaN(score) && score >= 0);

  let overallScore = 0;
  if (validScores.length > 0) {
    overallScore = (validScores.reduce((acc, score) => acc + score, 0) / validScores.length);
  }
  const overallScoreNumeric = Math.round(parseFloat(overallScore.toFixed(2)));

  const getMessageByScore = (score) => {
    if (score >= 90) {
      return "Your page is excellent! Keep it up!";
    } else if (score >= 75) {
      return "Great job! Your page could be better with minor adjustments.";
    } else if (score >= 50) {
      return "Your page is good, but there are opportunities for enhancement.";
    } else {
      return "Your page could be better. Consider optimizing more.";
    }
  };
  const getMessageByUsabilityScore = (score) => {
    if (score >= 90) {
      return "Your site's usability is excellent! Users will love it.";
    } else if (score >= 75) {
      return "Good usability! A few tweaks could make it even better.";
    } else if (score >= 50) {
      return "Usability is decent but could be more intuitive.";
    } else {
      return "Usability is a concern. Focus on improving user experience.";
    }
  };

  const getMessageByPerformanceScore = (score) => {
    if (score >= 90) {
      return "Your site's performance is excellent! It's fast and efficient.";
    } else if (score >= 75) {
      return "Good performance, but there's room to speed things up.";
    } else if (score >= 50) {
      return "Performance is average. Consider optimizing speed.";
    }else if(score === undefined || score===0){
      return null;
    } 
    
    else {
      return "Your site’s performance is low. Speed optimizations are necessary.";
    }
  };
  const getMessageByOverallScore = (score) => {
    if (score >= 90) {
      return "Your website's overall performance is excellent! Keep up the great work!";
    } else if (score >= 75) {
      return "Good job! Your website is performing well, but there are areas for improvement.";
    } else if (score >= 50) {
      return "Your website is performing decently, but there are significant areas that need attention.";
    } else {
      return "Your website's overall score is low. Focus on making improvements in key areas.";
    }
  };

  // Function to render a simple bar graph based on the frequency
  const renderGraph = (frequency) => {
    const barStyle = {
      height: '10px',
      width: `${frequency * 10}px`, // Scale width based on frequency
      backgroundColor: '#3b82f6',
      borderRadius: '5px',
    };
    return <div style={barStyle} />;
  };

  let formattedUrl;
  if (results.sslDetails?.valid) {
    formattedUrl = results.url && (results.url.startsWith('http://') || results.url.startsWith('https://'))
      ? results.url.replace(/^http:\/\//, 'https://')
      : `https://${results.url || 'default-url.com'}`;
  } else {
    formattedUrl = results.url && (results.url.startsWith('http://') || results.url.startsWith('https://'))
      ? results.url
      : `https://${results.url || 'default-url.com'}`;
  }
  const getSERPPreview = () => {
    if (!results) return null;
    const { title = "No title available", metaDescription = "No description available" } = results;
    return (
      <div className="serp-preview-container">
        <div className="serp-preview-url">{formattedUrl}</div>
        <div className="serp-preview-title">{title}</div>
        <div className="serp-preview-description">{metaDescription}</div>
      </div>
    );
  };
  const getSERPPreviewMobile = () => {
    if (!results) return null;
    const { title = "No title available", metaDescription = "No description available" } = results;
    return (
      <div className="serp-preview-mobile-container">
        <div className="serp-preview-mobile-url">{formattedUrl}</div>
        <div className="serp-preview-mobile-title">{title}</div>
        <div className="serp-preview-mobile-description">{metaDescription}</div>
      </div>
    );
  };


  return (
    <>
    <div  className="landing-page-container1">    
      <div className="content-container1">
      {/* <div  style={tss.avoidBreak}> */}
      <div>
            <div >
              <h3 style={tss.titleStyleHeader}>Website Report for
                <span style={tss.titleStyleLabel}> {formattedUrl}
                  </span>
                </h3>
            </div>
            <div>
              <p style={tss.resultHeader}>
                This report grades your website based on the strength of various SEO factors such as On Page Optimization, Off Page Links, Social and more. Improving your SEO will generally make your website perform better for users and rank better in search engines. There are recommendations for improving your website at the bottom of the report. Feel free to reach out of us if you'd like us to help with improving your website's SEO!
              </p>
            </div>
          </div>
        <div id='result-content' style={{
          marginTop: "20px", marginLeft: "30px", marginRight: "10px", fontFamily: "'Poppins', sans-serif",
        }}>
         

          <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Audit Result for {formattedUrl}</h3>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                <strong style={{ fontWeight: "bold" }}></strong> <div id='result-content' style={{ marginTop: '20px', marginLeft: '30px', marginRight: '10px', fontFamily: "'Poppins', sans-serif" }}>

                  <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', flexDirection: 'column', marginBottom: '40px' }}>
                    <div >
                      <h4>SEO Score</h4>
                      <div style={{ width: '70px', margin: '10px' }}>
                        <CircularProgressbar
                          value={seoScore}
                          text={`${seoScore}%`}
                          styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(seoScore) })}
                          />
                      </div>
                      <p style={{ marginTop: '-55px', paddingLeft: '100px', paddingBottom: '35px' }}>
                        {getMessageByScore(seoScore)}
                      </p>
                    </div>



                    <div >
                      <h4>Usability</h4>
                      <div style={{ width: '70px', margin: '10px' }}>
                        <CircularProgressbar
                          value={usabilityScore}
                          text={`${usabilityScore}%`}
                          styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(usabilityScore) })}
                          />
                      </div>
                      <p style={{ marginTop: '-55px', paddingLeft: '100px', paddingBottom: '35px' }}>
                        {getMessageByUsabilityScore(seoScore)}
                      </p>
                    </div>

                    <div >

                      {results?.pageSpeedResults?.speed ? (
                        <>
                          <h3>Performance</h3>

                          <div style={{ width: '70px', margin: '10px' }}>
                            <CircularProgressbar
                              value={performanceScore}
                              text={`${performanceScore}%`}
                              styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(performanceScore) })}
                              />
                          </div>
                          <p style={{ marginTop: '-55px', paddingLeft: '100px', paddingBottom: '35px' }}>
                      {getMessageByPerformanceScore(seoScore)} {/* Message for SEO */}
                    </p>
                        </>
                      ) : (
                        // This can be a placeholder, a loading spinner, or anything else while the speed is not yet available.
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',  
                          // alignItems: 'center',      
                          height: '100%',            
                          fontSize: '16px',        
                          // fontWeight: 'bold',        
                        }}>⏳ Loading performance data...</div>
                      )}

                    </div>
               
                    <div >

                      <h4>Overall Score</h4>
                      <div style={{ width: '70px', margin: '10px' }}>
                        <CircularProgressbar
                          value={overallScoreNumeric}
                          text={`${overallScoreNumeric}%`}
                          styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(overallScoreNumeric) })}
                          />
                      </div>
                    </div>
                    <p style={{ marginTop: '-55px', paddingLeft: '100px' }}>
                      {getMessageByOverallScore(overallScoreNumeric)}
                    </p>

                  </div>


                </div>

              </p>
            </div>
          </div>


          <MetaTagsTnD />
          <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Search Engine Results Page (SERP)
              </h3>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                <strong style={{ fontWeight: "bold" }}>SERP for Desktop:</strong> {getSERPPreview()}
              </p>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                <strong style={{ fontWeight: "bold" }}>SERP for Mobile:</strong>   {getSERPPreviewMobile()}
              </p>
            </div>
          </div>


          <HeadingTags />
          <div style={{ ...tss.cardDesign, ...tss.avoidBreak, ...tss.darkmode }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Headings</h3>
            </div>
            <div style={tss.contentWrapper}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={tss.tableHeader}>Heading Tag</th>
                    <th style={tss.tableHeader}>Frequency</th>
                    <th style={tss.tableHeader}>Graph</th>
                  </tr>
                </thead>
                <tbody>
                  {Object?.keys(results.headings).map((tag, index) => (
                    <tr key={index}>
                      <td style={tss.tableCell}>{tag.toUpperCase()}</td>
                      <td style={tss.tableCell}>{results.headings[tag].length}</td>
                      <td style={tss.tableCell}>{renderGraph(results.headings[tag].length)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <CodeToTextRatio />
          <FlashContent />
          <Iframes />
          <DomainInfo />
          <FaviconCheck />
          <CanonicalTagCheck />
          <LanguageCheck />
          <HreflangUsage />
          <XmlSitemap />
          <HtmlSitemapCheck />
          <RobotsTxt />
          <SslCertificateDetails />
          <EmailPrivacy />
          <MissingAltTags />
          <DuplicateContent />
          <SocialMediaProfiles />

          {results?.pageSpeedResults?.speed ? (
            <GooglePageSpeed />
            
          ) : (
            
            <div style={{
              display: 'flex',
              justifyContent: 'center',  
              // alignItems: 'center',    
              height: '100%',            
              fontSize: '18px',          
              fontWeight: 'bold',        
            }}>⏳ Loading Goodle PageSpeed data...</div>
          )}
          <AnalyticsPresence />
          <SeoScorePercent />
          <HttpsRedirectCheck />
          <BrokenLinks />
          <BusinessProfile1 />
          {/* <SuggestedKeywords /> */}
        </div>
      </div>
    </div>
</>
);
};

export default Result;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
 
    border:"0px solid white",
    borderRadius: "8px",
    padding: "0px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {

    backgroundColor:"red",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px", // Added for some top spacing
    paddingBottom: "10px" // Added for some bottom spacing
  },
  
  titleStyleLabel: {
    backgroundColor:"orange",
    padding:"5px",
    borderRadius:"10px",
    marginLeft:"10px",
    textAlign:"center",
    
  },
  
  
  titleStyle: {
    fontSize: "1.1rem", // Adjusted for better clarity
    fontWeight: "600",
    margin: "0",
  },
  titleStyleHeader: {
    fontSize: "1.6rem", // Adjusted for better clarity
    fontWeight: "700",
    margin: "0",
    color:"#fff",
    textAlign: "center",
    paddingTop:"30px",
    
  },
  
  resultStyle: {
    padding:"20px",
    fontSize: "0.9rem", // Increased for better readability
    color: "#4b5563",
    // backgroundColor:"white"
    
  },
  resultHeader: {
    padding:"40px",
    fontSize: "0.9rem", // Increased for better readability
    color: "#fff",
    // backgroundColor:"white"
    
  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto", // Helps with responsive layout
    marginBottom: "20px", // Space between sections
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    padding: "10px",
    textAlign: "center", // Center aligned
    fontWeight: "bold",
    backgroundColor: "#f1f1f1",
    // border: "1px solid #ddd",
  },
  tableCell: {
    padding: "8px",
    textAlign: "center", // Center aligned for consistency
    border: "1px solid #ddd",
  },
  graphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphBar: {
    height: "10px",
    backgroundColor: "#3b82f6",
    borderRadius: "5px",
    margin: "auto", // Center the bar in the column
  },
  darkmode: {
    color: "black",
  }
};

