import React from 'react'
import { useSEO } from './stateContext';
// import './style.css'

const SocialMediaProfiles = () => {
    const {results}=useSEO();
  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
    <div style={tss.headerBackground}>
      <h3 style={tss.titleStyle}>Social Media Profiles</h3>
    </div>
    <div style={tss.contentWrapper}>
      <p style={tss.resultStyle}>
        <strong style={{ fontWeight: "bold" }}></strong>  {Object.keys(results.socialMediaProfiles).map((profile) => (
          <li key={profile} style={{ marginBottom: "8px", color: "#666" }}>
            <strong>{profile.charAt(0).toUpperCase() + profile.slice(1)}:</strong>{" "}
            <span style={{ color: results.socialMediaProfiles[profile] ? "#28a745" : "red", fontWeight: "bold" }}>
              {results.socialMediaProfiles[profile] || "Not Found"}
            </span>
          </li>
        ))}
      </p>
    </div>
  </div>  )
}

export default SocialMediaProfiles

const tss = {
    cardDesign: {
      position: "relative",
      backgroundColor: "#f8f9fa",
      borderRadius: "8px",
      marginBottom: "16px",
      fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
      backgroundColor:"red",
      display: "flex",
      color: "white",
      paddingLeft: "15px",
      paddingTop: "10px", 
      paddingBottom: "10px" 
    },
    titleStyle: {
      fontSize: "1.1rem",
      fontWeight: "600",
      margin: "0",
    },
    resultStyle: {
      fontSize: "0.9rem",
      color: "#4b5563",
      paddingLeft:"15px"

    },
    contentWrapper: {
      padding: "10px 0",
      fontSize: "0.9rem",

    },
    // screenshotStyle: {
    //   width: "100%",
    //   borderRadius: "8px",
    // },
    // // Table styles
    // table: {
    //   width: "100%",
    //   borderCollapse: "collapse",
    //   marginTop: "10px",
    // },
    // tableHeader: {
    //   backgroundColor: "#f0f0f0",
    //   borderBottom: "2px solid #dee2e6",
    //   padding: "8px",
    //   textAlign: "left",
    //   fontWeight: "bold",
    // },
    // tableCell: {
    //   padding: "8px",
    //   borderBottom: "1px solid #dee2e6",
    // },
    avoidBreak: {
      pageBreakInside: "avoid",
    },
  };
