import React, { useState } from 'react';
import { useSEO } from './stateContext';

const HeadingsTags = () => {
    const { results } = useSEO();
    const [expandedTags, setExpandedTags] = useState({}); // To track which tags are expanded

    const toggleExpand = (tag) => {
        setExpandedTags((prevState) => ({
            ...prevState,
            [tag]: !prevState[tag], // Toggle the expanded state
        }));
    };

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak, ...tss.darkmode }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Headings</h3>
            </div>
            <div style={tss.contentWrapper}>
                {Object.keys(results.headings).map((tag, index) => {
                    const isMultiple = results.headings[tag].length > 1;
                    const isExpanded = expandedTags[tag]; // Check if the tag is expanded
                    const itemsToShow = isExpanded
                        ? results.headings[tag]
                        : results.headings[tag].slice(0, 5); // Show only the first 5 if not expanded

                    return (
                        <div key={index} style={tss.tagWrapper}>
                            <h4
                                style={{
                                    ...tss.tagTitleStyle,
                                    color: isMultiple ? 'red' : 'inherit',
                                }}
                            >
                                {tag.toUpperCase()} ({results.headings[tag].length})
                            </h4>
                            <ul style={tss.listStyle}>
                                {itemsToShow.map((content, idx) => (
                                    <li key={idx} style={tss.listItemStyle}>
                                        {content}
                                    </li>
                                ))}
                            </ul>
                            {results.headings[tag].length > 5 && (
                                <p
                                    onClick={() => toggleExpand(tag)}
                                    style={tss.readMoreText}
                                >
                                    {isExpanded ? 'Read Less' : 'Read More'}
                                </p>
                            )}
                            {/* Show error message if tag is used more than once */}
                            {isMultiple && (
                                <p style={tss.warningStyle}>
                                    ⚠️ {tag.toUpperCase()} tag is used more than once.
                                </p>
                            )}
                            {/* Add clickable "Read More" or "Read Less" text */}
                     
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HeadingsTags;

const tss = {
    // Same styles as before
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        border: "0px solid #dee2e6",
        borderRadius: "8px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        backgroundColor: "red",
        display: "flex",
        alignItems: "center",
        color: "white",
        padding: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    titleStyle: {
        fontSize: "1.1rem",
        fontWeight: "600",
        margin: "0",
    },
    contentWrapper: {
        padding: "20px 0",
    },
    tagWrapper: {
        marginBottom: "20px",
        padding: "10px 0",
        borderBottom: "1px solid #ddd",
        paddingLeft: "15px",
    },
    tagTitleStyle: {
        fontSize: "0.8rem",
        fontWeight: "500",
        margin: "0",
        textAlign: "left",
    },
    listStyle: {
        paddingLeft: "20px",
        marginTop: "10px",
        listStyleType: "disc",
    },
    listItemStyle: {
        fontSize: "0.8rem",
        lineHeight: "1.5",
        color: "#333",
    },
    warningStyle: {
        color: "red",
        fontSize: "0.8rem",
        marginTop: "10px",
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
    darkmode: {
        color: "black",
    },
    readMoreText: {
        color: "#007bff", // Blue color
        cursor: "pointer", // Pointer cursor to indicate it's clickable
        fontSize: "0.8rem", // Match the size with the content
        marginTop: "10px",
        // textDecoration: "underline", // Add underline to indicate it's a link
    },
};
