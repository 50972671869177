import React from 'react';
import { useSEO } from './stateContext';

const AnalyticsPresence = () => {
    const { results } = useSEO();

    // Determine star rating based on analytics presence
    const stars = results.analyticsPresent ? 5 : 1;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    // Function to render message based on analytics presence
    const renderMessage = () => {
        if (results.analyticsPresent) {
            return (
                <p style={tss.benefitText}>
                    ✅ Your page has analytics set up! This helps track user behavior and improve SEO.
                </p>
            );
        } else {
            return (
                <div style={tss.warningContainer}>
                    <span style={tss.warningIcon}>⚠️</span>
                    <span>
                        <p>❌ No analytics found.</p>
                        <p style={tss.warningText}>Analytics is essential for tracking user interactions and improving SEO strategies.</p>
                    </span>
                </div>
            );
        }
    };

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Analytics Presence</h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <strong style={{ fontWeight: "bold" }}>Analytics Presence:</strong>{" "}
                    {results.analyticsPresent ? "Analytics is present." : "No analytics found."}
                </p>
                {renderMessage()}
            </div>
        </div>
    );
};

export default AnalyticsPresence;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        backgroundColor:"red",
        display: "flex",
        alignItems: "center",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px", // Added for some top spacing
        paddingBottom: "10px" // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: "1.1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.9rem",
        color: "#4b5563",

    },
    contentWrapper: {
        padding: "10px 0",
        paddingLeft:"15px"

    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
        paddingLeft:"15px"

    },
    warningIcon: {
        fontSize: '0.9rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    benefitText: {
        fontSize: '0.9rem',
        color: '#28a745',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};
