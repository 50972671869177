
import React from 'react';
import { useSEO } from './stateContext';

const SslCertificateDetails = () => {
    const { results } = useSEO();

    // Function to render stars based on SSL validity
    const renderStars = (isValid) => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {isValid ? '★★★★★' : '☆☆☆☆☆'}
        </span>
    );

    // Function to format the date (only showing YYYY-MM-DD)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];  // Get the date in 'YYYY-MM-DD' format
    };

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak, ...tss.darkmode }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>SSL Certificate Details</h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars(results.sslDetails.valid)}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                {/* <p style={tss.resultStyle}>
                    <strong style={{ fontWeight: "bold" }}>SSL Available:</strong> {results.sslDetails.valid ? "Yes" : "No"}
                </p> */}
                {results.sslDetails.valid && (
                    <>
                      
                        <table style={tss.table}>
        <thead>
          <tr>
            <th style={tss.tableHeader}>Field</th>
            <th style={tss.tableHeader}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tss.tableCell}>Domain Name</td>
            <td style={tss.tableCell}>{results.domainInfo?.domainName || "N/A"}</td>
          </tr>
          <tr>
            <td style={tss.tableCell}>SSL Available</td>
            <td style={tss.tableCell}>{results.sslDetails.valid ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td style={tss.tableCell}>Valid From:</td>
            <td style={tss.tableCell}> {formatDate(results.sslDetails.validFrom) || "N/A"}</td>
          </tr>
          <tr>
            <td style={tss.tableCell}>Valid To:</td>
            <td style={tss.tableCell}> {formatDate(results.sslDetails.validTo) || "N/A"}</td>
          </tr>
          <tr>
            <td style={tss.tableCell}>Days Left:</td>
            <td style={tss.tableCell}>{results.sslDetails.daysRemaining || "N/A"}</td>
          </tr>
        </tbody>
      </table>
                    </>
                )}
            </div>
        </div>
    );
};

export default SslCertificateDetails;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        backgroundColor:"red",
        display: "flex",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    titleStyle: {
        fontSize: "1.1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.9rem",
        color: "#4b5563",
    },
    contentWrapper: {
        padding: "10px 0",
        paddingLeft:"15px"

    },
    // Table styles
    table: {
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "10px",

      },
      tableHeader: {
        backgroundColor: "#f0f0f0",
        // borderBottom: "2px solid #dee2e6",
        padding: "8px",
        textAlign: "left",
        fontWeight: "bold",
      },
      tableCell: {
        padding: "8px",
        borderBottom: "1px solid #dee2e6",
        fontSize:"0.9rem",
        paddingLeft:"15px"

      },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
    darkmode:{
      color:"black",
    }
};
