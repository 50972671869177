// import React from 'react'
// import logo from "../assets/contact.jpg"
// import "./contact.css"
// import Footer from './Footer/Footer'
// const Contact = () => {
//   return (
//     <>
//     <div className="contact-page">
//       <div className="contact-section">
//         <h2>Contact</h2>
//         <h1>Get in touch with us anytime</h1>
//         <p>We will be happy to assist you in a way we can.</p>
//         <p className='mail'>You can also reach us at <a href="mailto:support@crawlmyweb.com">support@crawlmyweb.com</a> </p>

//         <div className="address">
//           {/* <h3>Address:</h3>
//           <p>Crawl My Web</p>
//           <p>Greater Noida West</p>
//           <p>Uttar Pradesh</p> */}
//         </div>
//       </div>
//       <div className='image-section'>
//       <img src={logo} alt="crawlmyweb.com" />
//       </div>
//     </div>
//     <Footer />
//     </>
//   )
// }

// export default Contact
import React, { useState } from "react";
import logo from "../assets/contact.jpg";
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    query: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Simulate an email submission using a backend service.
      const response = await fetch(process.env.REACT_APP_MAILER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your query has been submitted successfully!");
      } else {
        alert("Failed to send the query. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="contact-page">
        <div className="contact-section">
          <h2>Contact</h2>
          <h1>Get in touch with us anytime</h1>
          <p>We will be happy to assist you in any way we can.</p>
          <p className="mail">
            You can also reach us at{" "}
            <a href="mailto:support@crawlmyweb.com">support@crawlmyweb.com</a>
          </p>

          <form className="contact-form" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                // placeholder="Enter Email"
                required
              />
            </div>
            <div>
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                      //  placeholder="Enter Phone number"
                required
              />
            </div>
            <div>
              <label htmlFor="query">Query:</label>
              <textarea
                id="query"
                name="query"
                rows="4"
                value={formData.query}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="image-section">
          <img src={logo} alt="crawlmyweb.com" />
        </div>
      </div>
    </>
  );
};

export default Contact;
