import React from "react";
import "./SEOToolbox.css"; // Make sure to include the CSS file

const SEOToolbox = () => {

  const scrollToInput = () => {
    // Use window.scrollTo or element.scrollIntoView to scroll to the target element
    const inputElement = document.getElementById("search-input");
    if (inputElement) {
      inputElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="seo-toolbox-container">
      <h2 className="title">Introducing our SEO ToolBox</h2>
      <p className="subtitle">Tons of great features</p>
      <div className="features-grid">
        <div className="feature-item">
          <div className="icon">📊</div>
          <h3>Instantly analyze your SEO issues</h3>
          <p>
            Run unlimited analysis on our most powerful servers. Stored reports
            make it easy to view progress and past work.
          </p>
        </div>
        <div className="feature-item">
          <div className="icon">🔄</div>
          <h3>Professional SEO monitoring tools</h3>
          <p>
            Automatically keep track of weekly changes in more than 30 SEO
            variables. Get notifications if your SEO score changes.
          </p>
        </div>
        <div className="feature-item">
          <div className="icon">👥</div>
          <h3>Understand your competitors' SEO profile</h3>
          <p>
            Side-by-side SEO comparisons of up to 5 competitors. See how your
            SEO can improve against the competition.
          </p>
        </div>
        <div className="feature-item">
          <div className="icon">📝</div>
          <h3>Save hours with white-label SEO reports</h3>
          <p>
            Quickly create editable SEO reports for your clients or partner
            websites.
          </p>
        </div>
        <div className="feature-item">
          <div className="icon">📄</div>
          <h3>SEO reports you can understand and act upon</h3>
          <p>
            SEO broken down in plain language, with clear definitions and
            "how-to-fix" tutorials for each issue.
          </p>
        </div>
        <div className="feature-item">
          <div className="icon">🎁</div>
          <h3>7-Day free trial, cancel anytime!</h3>
          <p>Get started right away by registering below. Plans start at $29.95 per month.</p>
        </div>
      </div>
      <button className="cta-button" onClick={scrollToInput}>Start for Free</button>
    </div>
  );
};

export default SEOToolbox;
