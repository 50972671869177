import React, { useState } from "react";
import { useSEO } from "./stateContext";

const BrokenLinks = () => {
  const { results } = useSEO();
  const [showAll, setShowAll] = useState(false); // State to toggle "Read More" and "Read Less"

  // Function to calculate star rating based on the number of broken links
  const calculateBrokenLinksRating = (numBrokenLinks) => {
    if (numBrokenLinks === 0) return 5;
    if (numBrokenLinks <= 2) return 4;
    if (numBrokenLinks <= 5) return 3;
    if (numBrokenLinks <= 10) return 2;
    return 1;
  };

  // Number of broken links found
  const brokenLinksCount = results.brokenLinks ? results.brokenLinks.length : 0;
  const brokenLinksRating = calculateBrokenLinksRating(brokenLinksCount);

  // Function to render stars based on rating
  const renderStars = (rating) => (
    <span style={{ fontSize: "1.5rem", color: "#f39c12" }}>
      {"★".repeat(rating)}
      {"☆".repeat(5 - rating)}
    </span>
  );

  // Function to render the impact message
  const renderImpactMessage = (count) => {
    if (count === 0) {
      return (
        <p style={tss.benefitText}>
          ✅ **Great Job!** No broken links found. This ensures a seamless user
          experience, improves crawl efficiency for search engines, and
          positively impacts your SEO.
        </p>
      );
    } else {
      return (
        <p style={tss.errorText}>
          ❌ **Issues Detected!** Found {count} broken link
          {count > 1 ? "s" : ""}. Broken links can frustrate users, reduce site
          credibility, and negatively impact SEO. Make sure to fix these links
          to improve performance.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Broken Links</h3>
        <div style={{ marginLeft: "auto", marginRight: "10px" }}>
          {renderStars(brokenLinksRating)}
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <strong style={{ fontWeight: "bold" }}>Broken Links:</strong>{" "}
          {brokenLinksCount > 0 ? (
            <>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "15px",
                  marginTop: "10px",
                }}
              >
                {(showAll
                  ? results.brokenLinks
                  : results.brokenLinks.slice(0, 10)
                ).map((link, index) => (
                  <li key={index} style={{ marginBottom: "8px", color: "red" }}>
                    {link}
                  </li>
                ))}
              </ul>
              {brokenLinksCount > 10 && (
                // <button
                //   onClick={() => setShowAll(!showAll)}
                //   style={tss.readMoreButton}
                // >
                //   {showAll ? 'Read Less' : 'Read More'}
                // </button>
                <p
                  onClick={() => setShowAll(!showAll)}
                  style={tss.readMoreText}
                >{showAll ? 'Read Less' : 'Read More'}</p>
              )}
            </>
          ) : (
            <p style={{ color: "#28a745" }}>No broken links found.</p>
          )}
        </p>
        {/* Render the impact message */}
        {renderImpactMessage(brokenLinksCount)}
      </div>
    </div>
  );
};

export default BrokenLinks;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    backgroundColor: "red",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1.1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.9rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
    paddingLeft: "15px",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  errorText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
  readMoreText: {
    color: "#007bff", // Blue color
    cursor: "pointer", // Pointer cursor to indicate it's clickable
    fontSize: "1rem", // Match the size with the content
    marginTop: "10px",

    // textDecoration: "underline", // Add underline to indicate it's a link
},
};
